import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { x } from "@xstyled/styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { Title, Text } from "@atoms/Typography"
import Button from "@atoms/Button"
import Container from "@atoms/Container"
import config from "@data/SiteConfig"

function NotFoundPage({ data }) {
  const { blocks, meta } = data.page.nodes[0].frontmatter
  const { title, paragraphs } = blocks[0]

  return (
    <Container pt={100}>
      <Helmet title={`${meta.title} | ${config.siteTitle}`} />
      <x.div
        display="flex"
        h="100vh"
        justifyContent="center"
        alignItem="center"
      >
        <x.div textAlign="center">
          <StaticImage
            src="../assets/images/sadface.png"
            placeholder="none"
            alt="SQUAD Laptop"
            width="200px"
          />
          <Title
            as="h1"
            fontSize={{ _: 90, md: 144 }}
            color="brand.imabi.orange"
            textAlign="center"
          >
            {title}
          </Title>
          <Text variant="lg" textAlign="center">
            {paragraphs}
          </Text>
          <x.div mt="15px">
            <Button buttonMode="link" to="/" fullWidthButton>
              Go back Home
            </Button>
          </x.div>
        </x.div>
      </x.div>
    </Container>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    page: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "page-not-found" } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          slug
          meta {
            title
          }
          blocks {
            title
            paragraphs
          }
        }
      }
    }
  }
`
